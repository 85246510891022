import React, {useEffect, useRef} from "react"
import Single from "../../single"
import info1 from "../../../../static/img/irstrat/articulo8.png";
import noticias15 from "../../../../static/img/blog/noticias-15-.jpg";

const Detail = (props) => {
    const isMountedComponent = useRef(true)
    useEffect(() => {
        if (isMountedComponent.current) {
            window.jquery_scripts()
        }
        return () => {
            isMountedComponent.current = false
        }
    })

    return (
        <Single location={props.location} title='¿Cómo atraer inversores potenciales? '>
            <div className="main_blog_items">
                <div className="main_blog_item">
                    <div className="main_blog_image img_hover">
                        <img alt={" "} src={noticias15}/>
                    </div>
                    <div className="main_blog_text">
                        <div className="post_info">
                            <a href="#" className="date">
                                11 Ene. 2021
                            </a>
                            <div className="blog_author_area">
                 <span>
                  Por : Rodrigo Hernández
                </span>
                                <span>
                    Comunicación
                </span>
                            </div>
                        </div>

                        <h2>¿Cómo atraer inversores potenciales?   </h2>
                        <br/>
                    </div>
                </div>
                <div className="s_main_text">
                        <p>La selección de inversores (<em>investor targeting</em>) es el proceso que se lleva a cabo para identificar
                        posibles inversionistas que contribuyan a la generación incremental de valor para una compañía y
                        por ende para sus accionistas. En este sentido, el atraer inversores no es solo una parte fundamental
                        para el éxito de un programa de RI, sino el sello distintivo de un buen equipo de RI.   </p>

                        <p>De acuerdo con el estudio “IR Objectives and Challenges” realizado por IR Magazine en 2018, de
                        manera global, la selección de inversores es la prioridad principal de los equipos de RI, pues un 75%
                        de los directores de relación con inversionistas (IROs) entrevistados lo clasificaron como una de las
                        tres metas principales de su estrategia y 37% lo colocaron como el objetivo central. </p>

                        <p>No obstante, la colocación efectiva de los inversionistas institucionales “correctos” frente a la
                        administración adecuada, en el momento apropiado, requiere más que una lista de los principales
                        inversionistas y administraciones. Ya sea que el ejercicio de atracción sea una vez al año o cada
                        trimestre, la clave es encontrar los métodos que mejor funcionen para cada compañía, sin importar
                        el grado de complejidad del proceso. Con ello en mente, a continuación se mencionan algunas de las
                        mejores prácticas diseñadas para impulsar las estrategias de atracción de inversores potenciales:   </p>

                        <p>• <b><em>Entender al inversor y a uno mismo.</em></b> Antes de comenzar con una lista de inversores
                        potenciales, es importante tener claro la historia de la empresa, comprender cómo es
                        percibida por los inversionistas, analizar si su posicionamiento y estrategia es consistente con
                        las características que pretenden tenga su base de inversión, y estar al tanto de las cualidades
                        objetivo de los asociados financieros de sus pares (<em>peers</em>). </p>

                        <p>De esta forma, al conocer la empresa, se puede comprender a los inversores potenciales.
                        Toda vez que sean seleccionados los candidatos, será de suma importancia conocer qué tipo
                        de inversores son (cuáles son sus metas y cómo están conformadas sus carteras) con el
                        propósito de simplificar la lista y seleccionar únicamente aquellos cuyo perfil se alinee al de
                        la empresa. </p>

                        <p>• <b><em>Es un maratón, no un sprint.</em></b> La orientación hacia los principales inversores es un ejercicio
                        continuo, que consiste en cultivar nuevas relaciones y nutrir las existentes. Es un proceso que
                        conlleva el desarrollo de relaciones, esto con el propósito de fomentar la diversidad de
                        perspectivas que tengan en común el bienestar de la empresa para alcanzar un punto en el
                        que exista confianza. Durante este proceso, es necesario establecer, en conjunto con el
                        equipo de liderazgo, metas y expectativas realistas para la viabilidad de cada potencial
                        inversor (por qué fueron seleccionados y los pasos necesarios para traerlos a bordo).</p>

                        <p>• <b><em>Lista de objetivos cambiante.</em></b>  Contar con una lista de los principales inversores institucionales
                        y los segmentos de inversores con los que los pares (<em>peers</em>) cuentan ya no es suficiente. La
                        lista de selección debe ser un documento que se modifique cada que se estime necesario.
                        En este contexto, el análisis del tráfico del sitio web permite conocer quién asiste a las conferencias telefónicas y quién está pendiente del reporte de resultados, de manera que se
                        vuelve una herramienta para rastrear aquellos inversores que están interesados en la
                        compañía, priorizándolos en la lista de objetivos. </p>

                        <p>• <b><em>Ampliación del alcance geográfico para mejorar el retorno de la inversión (ROI).</em></b> Para los IROs,
                        una forma de ser más eficaces con su tiempo es la eliminación o reducción de los viajes de
                        negocios, de manera que el tiempo ahorrado pueda servir para reservar una mayor cantidad
                        de reuniones virtuales de alta calidad en una variedad de zonas horarias. Por otro lado, si la
                        compañía cuenta con presencia en distintas regiones, es una buena oportunidad para que
                        los representantes asistan a eventos virtuales relativos al giro de la empresa en cada una de estas regiones,
                        con el fin de adquirir información sobre sus actuales inversionistas.</p>

                        <p>• <b><em>Aprovechar las tecnologías innovadoras.</em></b> En la actualidad, más del 50% de las empresas a
                        nivel mundial recurren a las plataformas tecnológicas para mejorar su orientación de
                        inversores. Por lo que, acceder a la plataforma correcta puede ayudar a identificar el poder
                        adquisitivo, el enfoque del sector y el estilo de inversión de los posibles inversores.
                        Adicionalmente, emplear inteligencia artificial puede ayudar a descubrir carteras que son
                        compatibles con el perfil deseado. </p>

                        <p>Referencias Bibliográficas
                          <ul>
                            <li>Q4. The Ultimate Guide to Targeting: 8 best practices for finding the right investors. Abril 8, 2019. [<a href="https://learn.q4inc.com/guide_targeting/" target="_blank">Q4</a>].</li>
                            <li>Q4. 5 Tips for Targeting Top Potencial Investors. [<a href="https://t.co/TAU019Cysc?amp=1" target="_blank">Inforgráfico</a>].</li>
                            <li>IR Magazine. IR Objectives and Challenges. Junio 27, 2018. [<a href="https://www.irmagazine.com/case-studies/ir-objectives-challenges-report-now-available" target="_blank">IR Magazine</a>].</li>
                          </ul>
                        </p>

                </div>
              </div>
        </Single>
    )
}

export default Detail
